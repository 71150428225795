import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import css from './GenericMessageModal.scss'

export interface IGenericMessageModalProps {
  onClose: () => void
  children: any
  headerLabel?: string
  headerType?: IHeaderType
  customClass?: string
}

export enum IHeaderType {
  SUCCESS = 'success',
  ERROR = 'error',
}

const GenericMessageModal = (props: IGenericMessageModalProps) => {
  const { onClose, children, headerLabel, headerType, customClass } = props

  return (
    <>
      <div className={css.modalBackground} />
      <div className={customClass ? `${css.modalBody} ${customClass}` : css.modalBody}>
        <div onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} className={css.close} />
        </div>
        {headerLabel && (
          <div className={css.header}>
            {headerType === IHeaderType.ERROR && <FontAwesomeIcon icon={faTimesCircle} className={css.errorIcon} />}
            <div className={headerType === IHeaderType.ERROR ? css.errorHeader : css.successHeader}>{headerLabel}</div>
          </div>
        )}
        <div className={css.innerBody}>{children}</div>
      </div>
    </>
  )
}

export default GenericMessageModal
